.terminal {
    position: absolute;
    background-color: #000000;
    backdrop-filter: blur(10px);
    border: 1px solid #333;
    border-radius: 5px;
    z-index: 1000;
    width: 500px;
    max-width: calc(100% - 20px);
    min-height: 300px;
    cursor: move;
    transition: all 0.3s ease;
    overflow: hidden;
  }
  
  .terminal.maximized {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
  
  .terminal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    background-color: #222222;
    border-bottom: 1px solid #555;
    border-radius: 5px 5px 0 0;
    user-select: none;
  }
  
  .terminal-header span {
    color: #dcdcdc;
    font-weight: bold;
  }
  
  .header-icons {
    display: flex;
    gap: 5px;
  }
  
  .header-icons svg {
    color: #dcdcdc;
    cursor: pointer;
  }
  
  .terminal-body {
    padding: 10px;
    color: #dcdcdc;
    font-family: "Courier New", Courier, monospace;
    font-size: 14px;
    height: calc(100% - 40px); /* Adjust based on header and padding */
    overflow: auto;
    text-align: start;
  }
  
  .input-field {
    appearance: none;
    border: none;
    background: transparent; /* Make the background transparent */
    color: transparent; /* Hide the text color */
    width: 0;
    outline: none;
    caret-color: transparent;
  }
  
  .prompt {
    color: #7fff23;
    user-select: none;
  }
  
  .user-input {
    color: #ffffff;
  }
  
  .output {
    margin-bottom: 8px;
  }
  
  .command {
    margin-bottom: 8px;
  }
  