.home-screen {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
}

.header {
  display: flex;
  justify-content: space-between;
  padding-left: 4px;
  padding-right: 15px;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
}

.right-header {
  margin: 2px;
}

.current-time {
  font-family: monospace;
  font-size: 15px;
}

.home-screen-icons {
  width: fit-content;
  display: flex;
  flex: 1;
  background-color: rgb(255 255 255 / 0.06);
  backdrop-filter: blur(10px);
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.icon-container {
  display: grid;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
  margin-left: 10px;
  width: 50px;
  justify-content: center;
  text-align: center;
}

.icon {
  font-size: 3em;
  margin-bottom: 10px;
  width: 50px;
  height: 50px;
  object-fit: contain;
}

.icon-header {
  width: 20px;
  height: 20px;
  object-fit: contain;
}

.icon-container:nth-child(1) {
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.icon-container:nth-child(2) {
  margin: 10px;
}

.icon-container:nth-child(3) {
  margin: 10px;
}

.icon-container:nth-child(4) {
  margin: 10px;
}

.bottom-icon {
  margin-top: auto;
  font-size: 4rem;
}