.file-explorer {
    position: absolute;
    background-color: #7d395533;
    backdrop-filter: blur(10px);
    border: 1px solid #333;
    border-radius: 5px;
    z-index: 1000;
    width: 700px;
    max-width: calc(100% - 20px);
    min-height: 200px;
    cursor: move;
    transition: all 0.3s ease;
    overflow: hidden;
  }
  
  .file-explorer-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    background-color: #222222;
    border-bottom: 1px solid #555;
    border-radius: 5px 5px 0 0;
    user-select: none;
  }
  
  .file-explorer.maximized {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 0;
  }

  .file-explorer-header .header-left {
    display: flex;
    align-items: center;
    margin-left: 5px;
  }
  
  .file-explorer-header .header-left > * {
    margin-right: 5px;
  }
  
  .file-explorer-header span {
    color: #dcdcdc;
    font-weight: bold;
  }

  .header-left svg {
    color: #dcdcdc;
    cursor: pointer;
  }
  
  .header-controls {
    display: flex;
    padding-left: 2px;
    align-items: center;
    justify-content: space-between;
  }

  .header-controls svg {
    margin-right: 8px;
  }

  .file-explorer-header .selected-item {
    background-color: #5293E1;
    padding: 3px 8px;
    border-radius: 3px;
    margin: 0 5px;
  }
  
  .file-explorer-body {
    display: flex;
    height: 100%;
  }
  
  .sidebar {
    width: 135px;
    background-color: #333845;
    border-right: 1px solid #ccc;
    padding: 10px;
    overflow-y: auto;
  }
  
  .sidebar-header {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .sidebar-list {
    list-style-type: none;
    padding: 0;
  }
  
  .sidebar-item {
    display: flex;
    align-items: center;
    padding: 8px 15px;
    cursor: pointer;
  }
  
  .sidebar-item.selected {
    background-color: #5293E1;
  }
  
  .sidebar-item-icon {
    font-size: 1.2rem;
    margin-right: 10px;
  }
  
  .main-content {
    flex: 1;
    padding: 20px;
    overflow-y: auto;
    background: white;
    color: grey;
  }
  
  .file-icon {
    width: 50px;
    height: 50px;
    object-fit: contain;
  }
  
  .file-grid {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 10px;
  }
  
  .file-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  
  .file-icon {
    font-size: 40px;
  }
  
  .file-name {
    margin-top: 5px;
    text-align: center;
  }
  